import Login from "components/Login";
import LoginLayout from "layouts/Login";
import { Box, Link, Divider } from "@chakra-ui/react";
import NextLink from "next/link";

export default function LoginPage() {
  return (
    <LoginLayout>
      <Login>
        <Divider my={6} />
        <Box textAlign={"left"}>
          <NextLink href='/reset' passHref>
            <Link>Mot de passe perdu ?</Link>
          </NextLink>
        </Box>
      </Login>
    </LoginLayout>
  );
}

